import { DiContainer } from '@jack-henry/frontend-utils/di';
import { Record } from '@treasury/FDL/record';
import { ChannelAuthenticationService } from '@treasury/domain/channel/services';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { companyId, loginId, password } from '@treasury/policy/user';
import { LitElement, css, html, nothing } from 'lit';
import { mix } from 'mixwith';
import channelAlertMixin from '../mix-ins/channel-alert-mixin.js';
// eslint-disable-next-line import/extensions
import { Client } from './client';
import './components/change-password.js';
import './components/forgot-password.js';
import './components/login.js';
import './components/oob-registration.js';
import './components/secure-token-registration.js';
import './components/security-questions.js';
import './components/user-selection';
import './components/user-verification.js';
import { LoginPhases, StatusCode } from './data/login-phases.js';

export const mockResultToChallenge = {
    statusCode: 'Challenge',
    message: 'Successful Login',
    data: [
        {
            answer: null,
            id: '26',
            text: 'What is the name of the hospital in which you were born?',
        },
        { answer: null, id: '39', text: 'What is the first name of your eldest nephew/niece?' },
        {
            answer: null,
            id: '56',
            text: "What is the first name of your father's youngest sibling?",
        },
    ],
    numberOfSecurityQuestions: 3,
    daysUntilPasswordExpires: 0,
    biometricsInfo: { authenticationId: null, key: null },
};

const genericErrorMessage =
    'We were unable to process your request at this time. Please try again in a moment.';

export class Container extends mix(LitElement).with(channelAlertMixin) {
    static get properties() {
        return {
            alert: Object,
            client: Object,
            institution: String,
            statusCode: {
                type: String,
                reflect: true,
            },
            loginResult: Object,
            loading: Boolean,
            errorMessage: String,
            isUisEnabled: Boolean,
            userOptions: Array,
            skipVerification: Boolean,
        };
    }

    constructor() {
        super();
        // this.loginResult = mockResultToChallenge;
        // this.statusCode = StatusCode.Register;
        this.loginRecord = new Record(
            {
                companyId,
                loginId,
                password,
            },
            {
                companyId: '',
                loginId: '',
                password: '',
            }
        );
    }

    async firstUpdated() {
        const di = await DiContainer.getInstance();
        this.client = di.get(Client);
        this.authService = di.get(AuthenticationService);
        this.channelAuthService = di.get(ChannelAuthenticationService);
        this.isUisEnabled = (await this.authService.uisMetadataPromise).isUisEnabled;
    }

    get hasLoginCode() {
        if (this.skipVerification) return false;
        const isSsoCallback =
            window.location.pathname.split('/').splice(2, 3).join('/') === 'login/sso';

        return isSsoCallback ? false : !!new URLSearchParams(window.location.search).get('code');
    }

    set hasLoginCode(value) {
        this.hasLoginCode = value;
    }

    connectedCallback() {
        super.connectedCallback();
        if (this.hasLoginCode) this.statusCode = StatusCode.UserVerification;
    }

    async login() {
        this.loading = true;
        try {
            this.errorMessage = null;
            const credentials = { ...this.loginRecord.values, institution: this.institution };
            this.loginResult = await this.client.login(credentials);
            const { statusCode, message } = this.loginResult;
            if (statusCode === 'Invalid') {
                this.errorMessage = message;
                return;
            }
            this.statusCode = statusCode;
        } catch (ex) {
            this.setAlertFromError(ex, genericErrorMessage);
        } finally {
            this.loading = false;
        }
    }

    forgotPassword() {
        this.statusCode = StatusCode.ForgotPassword;
    }

    resetStatusCode() {
        this.statusCode = undefined;
    }

    async saveSecurityQuestions(e) {
        this.loading = true;
        try {
            const success = await this.client.saveSecurityQuestions(e.detail.value);
            if (success) {
                await this.completeLogin();
            }
        } catch (ex) {
            this.setAlertFromError(ex, genericErrorMessage);
        } finally {
            this.loading = false;
        }
    }

    async verifySecurityQuestions(e) {
        this.loading = true;
        try {
            const response = await this.client.verifySecurityQuestions(e.detail.value);
            if (response.statusCode === StatusCode.Allow) {
                await this.completeLogin();
            }
        } catch (ex) {
            this.errorMessage = ex instanceof Error ? ex.message : genericErrorMessage;
        } finally {
            this.loading = false;
        }
    }

    async completeOutOfBandConfig() {
        await this.client.completeOutOfBandConfig();
        this.completeLogin();
    }

    async secureTokenRemindMeLater() {
        await this.client.secureTokenRemindMeLater();
        this.completeLogin();
    }

    async registerSecureToken(secureTokenDetails) {
        const { credentialId, token, pin } = secureTokenDetails;
        const defaultErrMessage = 'There was an error in your entry. Please try again.';

        try {
            this.errorMessage = null;
            const { success } = await this.client.registerSecureToken(credentialId, token, pin);
            if (success) {
                await this.completeLogin();
            } else {
                this.errorMessage = defaultErrMessage;
            }
        } catch (e) {
            this.errorMessage = e instanceof Error ? e.message : defaultErrMessage;
        }
    }

    async validateOneTimePassword({ oneTimePassword }, dialog) {
        const result = await this.client.validateOneTimePassword(oneTimePassword);
        if (result.status === 'Success') {
            dialog.open = false;
            this.checkTermsAndConditions();
        } else {
            dialog.verifying = false;
            dialog.securityMessage = {
                ...dialog.securityMessage,
                ...result,
            };
        }
    }

    async userSelected(userCompanyUniqueId) {
        const { message, alias, companyUniqueId, digitalId, userAccountSettingsLink } =
            await this.authService.authenticateSelectedCompanyUser(userCompanyUniqueId);

        const loginData = {
            institution: this.institution,
            companyId: companyUniqueId,
            loginId: alias,
            userName: alias,
            useRefreshTokens: false,
            digitalId,
            userAccountSettingsLink,
        };

        window.sessionStorage.setItem('user', JSON.stringify(loginData));

        this.statusCode = await this.channelAuthService.startAuthWorkflow({
            statusCode: StatusCode.Allow,
            message,
            numberOfSecurityQuestions: 0,
            daysUntilPasswordExpires: 0,
        });
    }

    async completeLogin() {
        const { isComplete, challengeMethodType, action } =
            await this.client.checkLoginCompletion();
        if (!isComplete) {
            switch (challengeMethodType) {
                // Legacy OOB
                case 1:
                    alert(
                        'verify-security-methods with challengeType 1 (legacy oob) -- not currently supported'
                    );
                    break;
                // Secure Token
                case 2:
                    this.statusCode = StatusCode.Register;
                    break;
                // ENS OOB
                case 3:
                    if (action === 'Challenge') {
                        this.statusCode = StatusCode.Challenge;
                    } else {
                        this.statusCode = StatusCode.RegisterOutOfBand;
                    }
                    break;
                default:
                    throw new Error(`Unknown challengeMethodType: ${challengeMethodType}`);
            }
            return;
        }

        this.checkTermsAndConditions();
    }

    async submitForgotPasswordForm({ detail }) {
        try {
            this.loading = true;
            this.forgotPasswordResponse = await this.client.forgotPassword(detail.formValues);
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    async submitUserVerificationForm({ detail }) {
        try {
            this.loading = true;
            const {
                statusCode,
                message,
                data,
                daysUntilPasswordExpires,
                numberOfSecurityQuestions,
            } = await this.client.verifyUser(detail.formValues);
            if (statusCode === StatusCode.Invalid) {
                this.alert = { ...this.alert, visible: true, type: 'error', message };
                return;
            }
            this.verifiedUserRecord = detail.record;
            this.verifiedUserResponse = {
                data,
                daysUntilPasswordExpires,
                numberOfSecurityQuestions,
            };
            this.statusCode = statusCode;
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    async submitPasswordChangeForm({ detail }) {
        try {
            this.loading = true;
            const changePasswordResponse = await this.client.updatePassword(detail.formValues);
            this.statusCode = changePasswordResponse.statusCode;
            this.hasLoginCode = false;
            this.completeLogin();
        } catch (e) {
            this.setAlertFromError(e);
        } finally {
            this.loading = false;
        }
    }

    getHeaderText() {
        try {
            return LoginPhases[this.statusCode].title;
        } catch (e) {
            throw new Error(`No title found for statusCode: "${this.statusCode}".`);
        }
    }

    async checkTermsAndConditions() {
        const result = await this.client.getTermsAndConditions();

        if (result && result.text) {
            window.location.href = `${this.institution}/terms-and-conditions-accept`;
        } else {
            window.location.href = `${this.institution}/dashboard`;
        }
    }

    renderErrorMessage() {
        if (!this.errorMessage) return nothing;
        return html`
            <div class="error">
                <omega-icon icon="times-circle"></omega-icon>
                ${this.errorMessage}
            </div>
        `;
    }

    renderPhase() {
        try {
            return LoginPhases[this.statusCode].render(this);
        } catch (e) {
            throw new Error(`No render function found for statusCode: "${this.statusCode}"`);
        }
    }

    render() {
        if (LoginPhases[this.statusCode]?.isDialog) return this.renderPhase();
        return html`
        ${this.renderAlert()}
            <div class="card">
                <div class="card-header">
                    <h1>${this.getHeaderText(this.statusCode)}</h1>
                    <img src="../../../branding/${this.institution}/logo.webp" alt=""></img>

                </div>
                ${this.renderErrorMessage()}
                ${this.renderPhase()}
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                --omega-primary: var(--brand-color);
            }
            .card-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 52px;
                padding: 0 15px;
                border-bottom: 1px solid #ccc;
            }
            .card-header img {
                max-height: 40px;
                max-width: 250px;
            }
            h1 {
                font-size: 20px;
                font-weight: 600;
                margin: 0;
            }
            .card {
                position: relative;
                background: #fff;
                box-shadow: 0 0 8px rgb(0 0 0 / 40%);
                width: clamp(300px, 100%, var(--login-card-max-width, 600px));
            }
            .error {
                border: 1px solid #ee3a3b;
                border-left: 4px solid #ee3a3b;
                padding: 7px 15px;
                margin: 15px;
                display: flex;
                align-items: center;
            }
            omega-icon {
                margin-right: 16px;
                color: #ee3a3b;
                font-size: 24px;
            }
            :host([statuscode='Register']) {
                --login-card-max-width: 800px;
            }
        `;
    }
}

export default Container;
window.customElements.define('login-container', Container);
